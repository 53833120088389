/**
 * Smoothly scrolls the page to the element with the specified ID.
 *
 * @param {string} elementId - The ID of the element to scroll to.
 This function scrolls to the target element using the `scrollIntoView` method with smooth scrolling behavior.
 *
 */
export const onScrollToDiv = ({ elementId, ...restParams }) => {
  setTimeout(() => {
    const divElement = document.getElementById(elementId);
    divElement &&
      divElement.scrollIntoView({ behavior: "smooth", ...restParams });
  }, 10);
};

export const is_empty = (str) => {
  return (
    !str ||
    0 === str.length ||
    (str.constructor === Object && Object.keys(str).length === 0) ||
    (str.constructor === String && str.trim().length === 0)
  );
};

// Helper function to trigger resize and scroll element into view
export const triggerResizeAndScroll = ({ id, throttleTime }) => {
  let resizeTimeout;
  clearTimeout(resizeTimeout);
  resizeTimeout = setTimeout(() => {
    window.dispatchEvent(new Event("resize"));
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, throttleTime);
};
